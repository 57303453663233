.MuseoSansRounded-300 { 
	font-family: MuseoSansRounded-300;
	font-weight: normal;
	font-style: normal;
}
.MuseoSansRounded-500 { 
	font-family: MuseoSansRounded-500;
	font-weight: normal;
	font-style: normal;
}
.MuseoSansRounded-700 { 
	font-family: MuseoSansRounded-700;
	font-weight: normal;
	font-style: normal;
}
.MuseoSansRounded-700Italic { 
	font-family: MuseoSansRounded-700Italic;
	font-weight: normal;
	font-style: normal;
}
.MuseoSansRounded-300Italic { 
	font-family: MuseoSansRounded-300Italic;
	font-weight: normal;
	font-style: normal;
}
.MuseoSansRounded-500Italic { 
	font-family: MuseoSansRounded-500Italic;
	font-weight: normal;
	font-style: normal;
}

body {
  margin: 0;
  padding: 0;

  font-family: Helvetica,Arial,sans-serif;
  font-size: 13px;
  line-height: 20px;

  background-color: #f0f2f5 !important;
}

p{
  font-family: 'Georgia Regular',Arial,sans-serif;
  font-size: 16px;
  line-height: 20px;
}


h1{
  /* font-family: 'SourceSansProRegular',sans-serif; */
  font-family: 'MuseoSansRounded-700';
  font-weight: 700;
}

h2{
  font-family: 'Raleway', sans-serif;
}

h3{
  font-family: 'MuseoSansRounded-700';
  font-size: 18px !important;
}

h4{
  font-family: 'Raleway',sans-serif;
}

h5{
  font-family: 'Raleway',sans-serif;
  font-size: 18px !important;
  font-weight: 700;
}

h6{
  font-family: 'Raleway',sans-serif;
  font-size: 12px !important;
  font-weight: 700;
}

.cardLink{
  font-family: 'MuseoSansRounded-700';
  font-size: 18px !important;
}


.date{
  font-family: 'MuseoSansRounded-500Italic';
  font-size: 12px !important;
}

.ingress{
  font-family: "MuseoSansRounded-500" !important;
  font-size: 16px;
  line-height: 20px;
}

.ant-btn-primary {
  color: #fff;
  background-color: #009c9b;
  border-color: #009c9b;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary:hover {
  border-color: white;
  background-color:#006666;
  border-color: #006666;

}

.ant-btn-primary:active {
  border-color: white;
  background-color:#006666;
  border-color: #006666;

}

.ant-btn-primary:focus {
  border-color: white;
  background-color:#006666;
  border-color: #006666;

}

.anticon {
  vertical-align: 0.1em;

}


/* NAVIGATION */
.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 0px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 0px solid transparent;
  background-color: #006666;
  color:white;
}

.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #006666 !important;
}

.ant-menu-horizontal > .ant-menu-item:hover {
  color: #009c9b !important;
  border-bottom: 0px solid #009c9b!important;
}

.ant-menu-horizontal > .ant-menu-item-selected > a:hover {
  color: white !important;
}

.ant-menu-item-selected > a, .ant-menu-item-selected > a:hover {
  color: #009c9b;
}

.ant-menu-item-selected {
  color: #66e2e2 !important;
  border-bottom: 0px solid #009c9b!important;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu:hover {
  position: relative;
  top: 0px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 0px solid transparent;
  background-color: #006666;
  color: white;
}

.ant-menu-submenu-title:hover {
  color: white;
}
.ant-menu-item-active {
  color: #009c9b;
}

/*BUTTON*/
.ant-btn-primary {
  font-family: 'Arial';
  font-weight: 600;
}

a{
  color:#333333 !important;
  font-size: 14px;
}

.ant-table-column-title{
  font-size: 11px;
}


.ant-card{
  box-shadow: 0 15px 35px rgba(50,50,93,0.1), 0 5px 15px rgba(0,0,0,0.07);
  margin-bottom: 20px;
}

.backlink{
    margin-bottom: 0.5em;
    color: #006666 !important;
    font-weight: 600;
    font-size: 38px;
    line-height: 1.23;
}

.ant-btn {
  color:white !important
}

.ant-modal-footer .ant-btn{
  color:black !important
}

.ant-modal-footer .ant-btn-primary{
  color:white !important
}

.prevbutton{
  color:black !important
}


.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-family: "MuseoSansRounded-500" !important;
  font-size: 16px;
  line-height: 20px;

}

.standOutCard{
  background-color: rgb(240, 240, 240);
}

.ant-card{
  border-radius: 6px;
}

.pathPart{
  color:lightgray;
}

.pathPartName{
  color:lightgray;
  font-family: 'MuseoSansRounded-700';
  font-size: 18px !important;
}
.pathPartTitle{
  color: #006666;
  font-family: 'MuseoSansRounded-700';
  font-size: 18px !important;

}


.ant-collapse-item-active{
  background: rgb(236, 236, 236);
}

.lightHeader{
  color:rgb(126, 125, 125);
}

.disabled{
  color:rgb(248, 242, 242);
}

.menuLink{ 
  color: grey !important
}

.answer{
  font-size: 24px;
  color: #006666;
  font-weight: 900;
  margin-top: 10px;
}

.actionTitle{
  color: #006666;
  font-weight: 900;
}

.ant-card-inline{
  box-shadow: 0 0px 0px rgba(50,50,93,0.1), 0 0px 0px rgba(0,0,0,0.07);
  margin-top: 20px;
  margin-bottom: 20px;
}


.ant-card-inline-nospace{
  box-shadow: 0 0px 0px rgba(50,50,93,0.1), 0 0px 0px rgba(0,0,0,0.07);
}

.ant-card-head-title{
  color: grey;
  font-family: "MuseoSansRounded-500" !important;
  font-weight: 900;
}

.medium{
  font-size: 14px;
}

.ant-card-body p{
  font-family: "MuseoSansRounded-500" !important;
}

.ant-card-actions > li > span a:not(.ant-btn){
  font-family: "MuseoSansRounded-300" !important;
  font-weight: 900;
}


@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}


.printSettings{
  -webkit-print-color-adjust: exact;
}
.app_logo__2UDV7 {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 24px 16px 0;
    float: left;
    background-image: url('/static/media/images/logo.png')
}

.app_headerBg__awjnJ{
    background: #006666
}
.signin_loginForm__2YAdc {
    max-width: 300px;
  }
.signin_loginFormForgot__3_l5k {
    float: right;
  }
.signin_loginFormButton__3DK1V {
    width: 100%;
  }
