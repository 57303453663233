.logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 24px 16px 0;
    float: left;
    background-image: url('/static/media/images/logo.png')
}

.headerBg{
    background: #006666
}